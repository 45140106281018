import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import classNames from 'classnames'
import Layout from 'components/layout'
import Head from 'components/head'
// import Box from 'components/box';
import PageBlocks from 'components/pageBlocks'
import Cta from 'components/cta/cta'
import { Formik } from 'formik'
import axios from 'axios'
import { navigate } from '@reach/router'

const Index = ({ data }) => {
  const fields = JSON.parse(data.buroBorkPage.field)

  return (
    <Layout locale={fields.locale} className="main-container">
      <Head
        pageTitle={fields.seo_title ? fields.seo_title : fields.title}
        locale={fields.locale}
        pageDescription={fields.meta_description}
      />
      <div className="page_row">
        <div className="bukazu bukazu-section-half  right">
          <div className="container-fluid">
            <div className="row bukazu-row">
              <div className="col-12 col-md-6 d-flex left px-4 align-items-center">
                <div className="bukazu-wrapper-content">
                  <div className="bukazu-content">
                    <h1 className="bukazu-h1-header">Maak verhuren simpel</h1>
                    <p className="bukazu-p">
                      Het online boekingssysteem dat je al het werk uit handen
                      neemt
                    </p>
                    <div className="training-mod">&nbsp;</div>
                    <Formik
                      initialValues={{
                        first_name: '',
                        last_name: '',
                        email: '',
                      }}
                      onSubmit={(values, actions) => {
                        setTimeout(() => {
                          // alert(JSON.stringify(values, null, 2))
                          actions.setSubmitting(false)
                          axios({
                            url: 'https://api.bukazu.com/graphql',
                            method: 'post',
                            data: {
                              query: `
                    mutation createUser($email: String!, $first_name: String, $last_name: String) {
                      createUser(is_trial: true, email: $email, first_name: $first_name, last_name: $last_name) {
                        id
                      }
                    }`,
                              variables: {
                                email: values.email,
                                first_name: values.first_name,
                                last_name: values.last_name,
                              },
                            },
                          }).then(result => {
                            if (result.data.errors) {
                              alert(
                                'Er is iets mis gegaan, probeer het later nog eens of neem contact met ons op'
                              )
                            } else {
                              navigate('/bedankt-voor-u-aanmelding')
                            }
                          })
                        }, 1000)
                      }}
                      render={({
                        values,
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="input-group bukazu-input-group mt-3">
                            <input
                              className="form-control input"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                              name="email"
                              type="text"
                              placeholder="Voer hier je e-mailadres in"
                              aria-required="true"
                              aria-invalid="false"
                            />
                            <div
                              className="custom-tooltip position-absolute"
                              style={{ display: 'none' }}
                            >
                              Wrong email adress
                            </div>
                            <div className="input-group-append ml-1">
                              <button className="bukazu-p-large btn btn-primary bukazu-btn text-white">
                                Registreer nu gratis
                              </button>
                            </div>
                          </div>
                        </form>
                      )}
                    ></Formik>
                    <p className="bukazu-p-medium mt-2">
                      Probeer 30 dagen gratis
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 px-4 d-flex right px-4 align-items-center ">
                <div className="">
                  <div className="bukazu-wrapper-image">
                    <img
                      className="bukazu-image"
                      src="../../images/top.jpg"
                      alt="Zelf vakantiehuis verhuren"
                      width="1364"
                      height="100%"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {fields.page_rows.map((row, index) => {
        let classes = 'page_row'
        if (row.options && row.options.classes) {
          classes = classNames(classes, Object.values(row.options.classes))
        }
        return (
          <div key={index} className={classes}>
            <PageBlocks blocks={row.page_blocks} />
          </div>
        )
      })}
      <Cta />
    </Layout>
  )
}

Index.propTypes = {
  data: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query BuroBorkIndex {
    buroBorkPage(slug: { eq: "/" }) {
      id
      field
    }
  }
`

export default Index
